<template>
  <div class="box">
    <b-icon icon="user" custom-size="fa-3x" class="icon-block"></b-icon>
    <h1 class="title has-text-centered">Авторизация</h1>
    <form @submit.prevent="submit">
      <b-field
        label="Email"
        :type="{
          'is-danger': $v.email.$error,
          'is-success': !$v.email.$invalid && $v.email.$dirty,
        }"
        :message="{ 'Некорректно указан email': $v.email.$error }"
      >
        <b-input
          placeholder="example@mail.com"
          :lazy="true"
          v-model.trim="$v.email.$model"
        ></b-input>
      </b-field>
      <b-field
        label="Пароль"
        :type="{
          'is-danger': $v.password.$error,
          'is-success': !$v.password.$invalid && $v.password.$dirty,
        }"
        :message="{ 'Некорректно указан пароль': $v.password.$error }"
      >
        <b-input
          type="password"
          placeholder=""
          :lazy="true"
          v-model.trim="$v.password.$model"
          password-reveal
        ></b-input>
      </b-field>
      <b-button
        native-type="submit"
        type="is-success"
        expanded
        icon-left="sign-in-alt"
        >Войти</b-button
      >
    </form>
    <div class="restore-password">
      <h6>
        Забыли пароль?
        <router-link to="/restore-password">Восстановить</router-link>
      </h6>
    </div>
    <div class="sign-up">
      <h6>
        Еще нет учетной записи?
        <router-link to="/sign-up">Зарегистрироваться</router-link>
      </h6>
    </div>
  </div>
</template>

<script>
import {
  minLength,
  maxLength,
  required,
  email,
} from 'vuelidate/lib/validators';

import API from '@/api';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  created() {
    if (this.$route.query.confirm === 'success') {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Ваш email успешно подтвержден. Вы можете войти в систему',
        position: 'is-bottom-right',
        type: 'is-success',
      });
    } else if (this.$route.query.confirm === 'false') {
      this.$buefy.toast.open({
        duration: 5000,
        message:
          'Мы не смогли верифицировать адрес вашей электронной почты. Обратитесь в службу поддержки.',
        position: 'is-bottom-right',
        type: 'is-danger',
      });
    }

    this.$store.commit('toggleLoader', false);
  },
  methods: {
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit('toggleLoader', true);

      const data = await API.login({
        email: this.email,
        password: this.password,
      });

      this.$store.commit('toggleLoader', false);

      if (data.success) {
        this.$router.push('/workspace');
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: data.message,
          position: 'is-bottom-right',
          type: 'is-danger',
        });
      }
    },
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(40),
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(30),
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 500px;
  background: #fff;
  position: relative;
  padding: 50px 30px 30px;
  color: #17a2b7;

  .icon-block {
    position: absolute;
    top: 0px;
    transform: translateY(-50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    left: 0;
    right: 0;
    margin: 0 auto;
    box-shadow: 0 -1px 0px 0px rgb(0 0 0 / 20%);
  }

  h1 {
    font-weight: 500;
  }

  form {
    width: 100%;

    button {
      margin-top: 20px;
    }
  }

  .restore-password,
  .sign-up {
    margin-top: 20px;

    h6 {
      text-align: center;

      a {
        font-weight: bold;

        &:hover {
          text-decoration: underline;
          color: #34578e;
        }
      }
    }
  }

  .sign-up {
    margin-top: 0;
  }
}
</style>
